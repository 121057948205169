/*
  Breakpoint          Class     Dimensions
  X-Small             None      <576px
  Small               sm        ≥576px
  Medium              md        ≥768px
  Large               lg        ≥992px
  Extra large         xl        ≥1200px
  Extra extra large	  xxl       ≥1400px
*/

@media screen and (min-width: 2400px) {
  body {
    .test-responsive {
      background-color: blueviolet;
      padding: 10px;
    }
  }
}