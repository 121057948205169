/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./styles/fonts";
@import "./styles/variables";
@import "./styles/toastr";
@import "./styles/theme";
@import "./styles/other-screen";
@import "./styles/tablet";
@import "./styles/mobile";

/* Formatter =================================================== */
body {
  margin: 0;
  padding: 0;
  color: $fut-blue;
  background: $bg-color;
  font-family: Poppins;
  font-size: 0.875rem;
  text-decoration: none;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
a:hover {
  text-decoration: none;
  cursor: pointer;
}

a:focus,
a:active,
button:focus,
button:active {
  outline: 0;
}

button:hover,
button:focus {
  outline: 0;
}

.clear {
  clear: both;
}

h1 {
  color: $fut-blue;
  font-weight: 700;
  font-family: Space-Grotesk;
}

h2 {
  color: $fut-blue;
  font-weight: 700;
  font-family: Space-Grotesk;
}

h3 {
  color: $fut-blue;
  font-weight: 700;
  font-family: Space-Grotesk;
}

h4 {
  color: $fut-blue;
  font-weight: 700;
  font-family: Space-Grotesk;
}

h5 {
  color: $fut-blue;
  font-weight: 700;
  font-family: Space-Grotesk;
}

h6 {
  color: $fut-blue;
  font-weight: 700;
  font-family: Space-Grotesk;
}

p {
  color: $fut-blue;
  margin-bottom: 0.5rem;
}

.fieldset {
  margin-bottom: 1.5rem;

  label {
    margin-bottom: 0.313rem;
    display: block;
  }

  input,
  .p-input-icon-left,
  .p-input-icon-right {
    width: 100%;
  }

  .p-dropdown {
    width: 100%;
  }
}

// ScrollBar ===================================================

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $fut-orange;
  background-clip: padding-box;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:horizontal {
  border-top: 0;
  border-bottom: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  // background: rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
}

// Spinner ===================================================
.loading-overlay {
  position: fixed;
  z-index: 10001;
  background: rgba(249, 249, 249, 0.25);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .wrap-spinner {
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 10005;
    top: 43.5%;

    .lds {
      width: 64px;
      height: 64px;
      margin: 0 auto;
      display: inline-block;
      text-align: center;
      position: relative;
    }

    .lds div {
      display: inline-block;
      position: absolute;
      left: 6px;
      width: 13px;
      background: #192b2f;
      animation: lds 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    .lds div:nth-child(1) {
      left: 6px;
      animation-delay: -0.24s;
    }

    .lds div:nth-child(2) {
      left: 26px;
      animation-delay: -0.12s;
    }

    .lds div:nth-child(3) {
      left: 45px;
      animation-delay: 0;
    }
  }
}

@keyframes lds {
  0% {
    top: 6px;
    height: 51px;
  }

  50%,
  100% {
    top: 19px;
    height: 26px;
  }
}

// CARDS =====================================================
.futuri-card {
  padding: 1.5rem;
  position: relative;
  background: $white;
  border-radius: $border-radius;
  box-shadow: 0px 12px 24px rgba(243, 243, 243, 0.4);
  z-index: 0;

  &.no-relative {
    position: initial;
  }

  &.external-page {
    border-radius: 24px;
    box-shadow: 0px 12px 32px #F3F3F3;
  }

  &.collapsible {
    .futuri-card-header {
      cursor: pointer;
    }

    .futuri-card-body {
      overflow: hidden;
    }
  }

  .futuri-card-header {
    margin-bottom: 24px;
  }

  &.extra-top-right::before {
    content: '';
    background: url('./assets/images/extra-card-top-right.svg');
    width: 211px;
    height: 59px;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 10%;
  }

  &.extra-bottom-left::after {
    content: '';
    position: absolute;
    background: url('./assets/images/extra-card-bottom-left.svg');
    width: 89px;
    height: 67px;
    z-index: -1;
    bottom: 0;
    left: 0;
  }

  &.extra-bottom-right::before {
    content: '';
    background: url('./assets/images/extra-card-bottom-right.svg');
    width: 68px;
    height: 37px;
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
  }
}

// TEMA BASE ====================================================
#main {
  padding: 2.5rem 0;
  background: $bg-main;
}

#main-nav {
  width: 220px;
  height: 100%;
  background: $white;
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0;
}

#main-body {
  margin-left: 220px;
  background: $bg-main-nav;
  z-index: 0;

  #main.bg-main {
    background: $bg-main-nav;
    min-height: calc(100vh - 208px);

    #secondary-header {
      padding: 0 45px;

      &.secondary-header {
        .breadcrumbs {
          margin-bottom: 5px;
          color: $fut-blue-light;

          span {
            margin-right: 5px;
          }

          .classe-detail {
            font-weight: 500;
          }
        }

        .title {
          .action-dot {
            background: none;
            padding: 0;
            margin: 0;
            border: 0;
            outline: 0;
            margin-top: -10px;
            margin-left: 10px;
          }
        }

        .info {
          font-weight: 500;
          color: $fut-blue-light;

          .header-info-scuola {
            margin-left: 10px;
            padding-left: 10px;
            border-left: 2px solid #ccc;
          }
        }
      }

    }

    .content {
      display: block;
      padding: 0 45px;
      margin: 20px 0 40px;

      &.second-dashboard {
        background: $fut-grey-neutral-20;
        border-radius: 32px;
        margin: 80px 0 40px;
        padding-bottom: 40px;
      }

      &.sticky {
        position: sticky;
        background: $fut-grey-neutral-20;
        top: 83px;
        margin: 0;
        padding: 0;
        z-index: 102;

        .futuri-card {
          padding: 10px;
          margin: 0;
          background: transparent;

          .futuri-card-header {
            display: none;
          }

          .futuri-card-body {
            .fieldset {
              margin: 0;

              label {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.overlay-profile {
  right: 0 !important;
  width: 100%;
  max-width: 180px;
}

// Conferma Dialog
.conferma-dialog.p-dialog {
  &::before {
    content: "";
    background: url("../src/assets/images/bg-conferma-popup.svg") no-repeat left top;
    display: block;
    width: 275px;
    height: 200px;
    position: absolute;
    z-index: -1;
    left: 0;
  }

  background: $bg-dialog;
  overflow: hidden;
}

// dashboard-accordion
.dashboard-accordion {
  .p-accordion-tab {
    padding: 1.5rem;
    background: $white;
    border-radius: 12px;
    margin-bottom: 25px !important;
    position: relative;
    z-index: 0;

    &::before {
      content: '';
      background: url('./assets/images/extra-card-bottom-right.svg');
      width: 68px;
      height: 37px;
      position: absolute;
      z-index: -1;
      right: 0;
      bottom: 0;
    }

    .p-accordion-header {
      .p-accordion-header-link {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 1.2rem;
        border-bottom: 0;
      }
    }
  }
}

// ICON

.futuri-icon {
  &:has(.icon-download) {
    &:hover {
      color: $fut-orange-dark;
    }

    &.xxl {
      font-size: 24px;
    }

    color: $fut-orange;
    margin-right: 20px;
    cursor: pointer;
  }
}

// PAGINE ESTERNE ===============================================
#login,
#not-found {
  #main {
    min-height: calc(100vh - 119px);
    padding-top: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-image: url('./assets/images/bg-registrazione.webp') !important;

    .p-password {
      width: 100%;

      .p-inputtext {
        width: 100%;
      }
    }
  }

  .external-wrap {
    margin-top: 10%;

    .futuri-card {
      width: 100%;
      max-width: 600px;

      .futuri-card-body {
        button {
          width: 100%;
        }
      }
    }
  }

}