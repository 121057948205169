/* Poppins Font =============================================================== */
@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-Thin.ttf') format("truetype");
  font-weight: 100;
  font-display: fallback;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-ThinItalic.ttf') format("truetype");
  font-weight: 100;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-ExtraLight.ttf') format("truetype");
  font-weight: 200;
  font-display: fallback;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-ExtraLightItalic.ttf') format("truetype");
  font-weight: 200;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-Light.ttf') format("truetype");
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-LightItalic.ttf') format("truetype");
  font-weight: 300;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-Regular.ttf') format("truetype");
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-Italic.ttf') format("truetype");
  font-weight: 400;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-SemiBold.ttf') format("truetype");
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-SemiBoldItalic.ttf') format("truetype");
  font-weight: 500;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-Bold.ttf') format("truetype");
  font-weight: 600;
  font-display: fallback;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-BoldItalic.ttf') format("truetype");
  font-weight: 600;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-ExtraBold.ttf') format("truetype");
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-ExtraBoldItalic.ttf') format("truetype");
  font-weight: 700;
  font-display: fallback;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-Black.ttf') format("truetype");
  font-weight: 800;
  font-display: fallback;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/poppins/Poppins-BlackItalic.ttf') format("truetype");
  font-weight: 800;
  font-display: fallback;
  font-style: italic;
}

/* Space Grotesk Font =============================================================== */
@font-face {
  font-family: Space-Grotesk;
  src: url('../assets/fonts/space-grotesk/SpaceGrotesk-Light.ttf') format("truetype");
  font-weight: 100;
  font-display: fallback;
}

@font-face {
  font-family: Space-Grotesk;
  src: url('../assets/fonts/space-grotesk/SpaceGrotesk-Regular.ttf') format("truetype");
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: Space-Grotesk;
  src: url('../assets/fonts/space-grotesk/SpaceGrotesk-Medium.ttf') format("truetype");
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: Space-Grotesk;
  src: url('../assets/fonts/space-grotesk/SpaceGrotesk-SemiBold.ttf') format("truetype");
  font-weight: 600;
  font-display: fallback;
}

@font-face {
  font-family: Space-Grotesk;
  src: url('../assets/fonts/space-grotesk/SpaceGrotesk-Bold.ttf') format("truetype");
  font-weight: 700;
  font-display: fallback;
}

/* Futuri Icons =============================================================== */

@font-face {
  font-family: 'futuri-icon';
  src: url('../assets/fonts/futuri-icon/futuri-icon.eot?69109051');
  src: url('../assets/fonts/futuri-icon/futuri-icon.eot?69109051#iefix') format('embedded-opentype'),
    url('../assets/fonts/futuri-icon/futuri-icon.woff2?69109051') format('woff2'),
    url('../assets/fonts/futuri-icon/futuri-icon.woff?69109051') format('woff'),
    url('../assets/fonts/futuri-icon/futuri-icon.ttf?69109051') format('truetype'),
    url('../assets/fonts/futuri-icon/futuri-icon.svg?69109051#futuri-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'futuri-icon';
    src: url('../font/futuri-icon.svg?69109051#futuri-icon') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "futuri-icon";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-more-h-circle-full:before {
  content: '\e800';
}

/* '' */
.icon-star-full:before {
  content: '\e801';
}

/* '' */
.icon-twitter:before {
  content: '\e802';
}

/* '' */
.icon-linkedin:before {
  content: '\e803';
}

/* '' */
.icon-facebook:before {
  content: '\e804';
}

/* '' */
.icon-star-regular:before {
  content: '\e805';
}

/* '' */
.icon-more-h-circle-regular:before {
  content: '\e806';
}

/* '' */
.icon-smile-1:before {
  content: '\e807';
}

/* '' */
.icon-smile:before {
  content: '\e808';
}

/* '' */
.icon-meh-1:before {
  content: '\e809';
}

/* '' */
.icon-grin-hearts:before {
  content: '\e80a';
}

/* '' */
.icon-grin-beam-1:before {
  content: '\e80b';
}

/* '' */
.icon-teach:before {
  content: '\e80c';
}

/* '' */
.icon-trend:before {
  content: '\e80d';
}

/* '' */
.icon-school:before {
  content: '\e80e';
}

/* '' */
.icon-preference:before {
  content: '\e80f';
}

/* '' */
.icon-lamp:before {
  content: '\e810';
}

/* '' */
.icon-hello:before {
  content: '\e811';
}

/* '' */
.icon-equality-1:before {
  content: '\e812';
}

/* '' */
.icon-diamond:before {
  content: '\e813';
}

/* '' */
.icon-cup:before {
  content: '\e814';
}

/* '' */
.icon-volume-up:before {
  content: '\e815';
}

/* '' */
.icon-video-slash:before {
  content: '\e816';
}

/* '' */
.icon-video:before {
  content: '\e817';
}

/* '' */
.icon-users-three:before {
  content: '\e818';
}

/* '' */
.icon-users:before {
  content: '\e819';
}

/* '' */
.icon-user:before {
  content: '\e81a';
}

/* '' */
.icon-upload:before {
  content: '\e81b';
}

/* '' */
.icon-unlock:before {
  content: '\e81c';
}

/* '' */
.icon-trash:before {
  content: '\e81d';
}

/* '' */
.icon-times-square:before {
  content: '\e81e';
}

/* '' */
.icon-thumbtack:before {
  content: '\e81f';
}

/* '' */
.icon-tablet:before {
  content: '\e820';
}

/* '' */
.icon-tag:before {
  content: '\e821';
}

/* '' */
.icon-store:before {
  content: '\e822';
}

/* '' */
.icon-stopwatch:before {
  content: '\e823';
}

/* '' */
.icon-sort-descending:before {
  content: '\e824';
}

/* '' */
.icon-sort-ascending:before {
  content: '\e825';
}

/* '' */
.icon-sliders-h:before {
  content: '\e826';
}

/* '' */
.icon-shopping-basket:before {
  content: '\e827';
}

/* '' */
.icon-shield:before {
  content: '\e828';
}

/* '' */
.icon-settings:before {
  content: '\e829';
}

/* '' */
.icon-send:before {
  content: '\e82a';
}

/* '' */
.icon-search:before {
  content: '\e82b';
}

/* '' */
.icon-questionnaire-2:before {
  content: '\e82c';
}

/* '' */
.icon-questionnaire:before {
  content: '\e82d';
}

/* '' */
.icon-question-circle:before {
  content: '\e82e';
}

/* '' */
.icon-plus-square:before {
  content: '\e82f';
}

/* '' */
.icon-phone:before {
  content: '\e830';
}

/* '' */
.icon-pen:before {
  content: '\e831';
}

/* '' */
.icon-paperclip:before {
  content: '\e832';
}

/* '' */
.icon-package:before {
  content: '\e833';
}

/* '' */
.icon-more-v-circle:before {
  content: '\e834';
}

/* '' */
.icon-mobile:before {
  content: '\e835';
}

/* '' */
.icon-minus-square:before {
  content: '\e836';
}

/* '' */
.icon-microphone-slash:before {
  content: '\e837';
}

/* '' */
.icon-microphone:before {
  content: '\e838';
}

/* '' */
.icon-menu:before {
  content: '\e839';
}

/* '' */
.icon-map-pin:before {
  content: '\e83a';
}

/* '' */
.icon-map-marker:before {
  content: '\e83b';
}

/* '' */
.icon-map:before {
  content: '\e83c';
}

/* '' */
.icon-log-out:before {
  content: '\e83d';
}

/* '' */
.icon-log-in:before {
  content: '\e83e';
}

/* '' */
.icon-lock:before {
  content: '\e83f';
}

/* '' */
.icon-link:before {
  content: '\e840';
}

/* '' */
.icon-info-circle:before {
  content: '\e841';
}

/* '' */
.icon-inbox:before {
  content: '\e842';
}

/* '' */
.icon-image:before {
  content: '\e843';
}

/* '' */
.icon-home:before {
  content: '\e844';
}

/* '' */
.icon-heart:before {
  content: '\e845';
}

/* '' */
.icon-headphones:before {
  content: '\e846';
}

/* '' */
.icon-globe:before {
  content: '\e847';
}

/* '' */
.icon-folder:before {
  content: '\e848';
}

/* '' */
.icon-flag:before {
  content: '\e849';
}

/* '' */
.icon-filter:before {
  content: '\e84a';
}

/* '' */
.icon-film:before {
  content: '\e84b';
}

/* '' */
.icon-file:before {
  content: '\e84c';
}

/* '' */
.icon-eye-slash:before {
  content: '\e84d';
}

/* '' */
.icon-eye:before {
  content: '\e84e';
}

/* '' */
.icon-exclamation-circle:before {
  content: '\e84f';
}

/* '' */
.icon-envelope:before {
  content: '\e850';
}

/* '' */
.icon-download:before {
  content: '\e851';
}

/* '' */
.icon-discount:before {
  content: '\e852';
}

/* '' */
.icon-desktop:before {
  content: '\e853';
}

/* '' */
.icon-credit-card:before {
  content: '\e854';
}

/* '' */
.icon-coupon:before {
  content: '\e855';
}

/* '' */
.icon-copy:before {
  content: '\e856';
}

/* '' */
.icon-compass:before {
  content: '\e857';
}

/* '' */
.icon-comments:before {
  content: '\e858';
}

/* '' */
.icon-comment-dots:before {
  content: '\e859';
}

/* '' */
.icon-comment:before {
  content: '\e85a';
}

/* '' */
.icon-clock:before {
  content: '\e85b';
}

/* '' */
.icon-chevron-up:before {
  content: '\e85c';
}

/* '' */
.icon-chevron-right:before {
  content: '\e85d';
}

/* '' */
.icon-chevron-left:before {
  content: '\e85e';
}

/* '' */
.icon-chevron-down:before {
  content: '\e85f';
}

/* '' */
.icon-chevron-circle-up:before {
  content: '\e860';
}

/* '' */
.icon-chevron-circle-right:before {
  content: '\e861';
}

/* '' */
.icon-chevron-circle-left:before {
  content: '\e862';
}

/* '' */
.icon-chevron-circle-down:before {
  content: '\e863';
}

/* '' */
.icon-check-circle:before {
  content: '\e864';
}

/* '' */
.icon-chart-pie:before {
  content: '\e865';
}

/* '' */
.icon-camera:before {
  content: '\e866';
}

/* '' */
.icon-calendar:before {
  content: '\e867';
}

/* '' */
.icon-bookmark:before {
  content: '\e868';
}

/* '' */
.icon-bell-slash:before {
  content: '\e869';
}

/* '' */
.icon-bell:before {
  content: '\e86a';
}

/* '' */
.icon-arrow-up:before {
  content: '\e86b';
}

/* '' */
.icon-arrow-right:before {
  content: '\e86c';
}

/* '' */
.icon-arrow-left:before {
  content: '\e86d';
}

/* '' */
.icon-arrow-down:before {
  content: '\e86e';
}

/* '' */
