/*
  Breakpoint          Class     Dimensions
  X-Small             None      <576px
  Small               sm        ≥576px
  Medium              md        ≥768px
  Large               lg        ≥992px
  Extra large         xl        ≥1200px
  Extra extra large	  xxl       ≥1400px
*/


@media screen and (max-width: 768px) {
  body {
    .test-responsive{
      background-color: blue;
    }

    .resizableDialog {
      max-height: 100% !important;
      width: 100% !important;
      height: 100% !important;
    }

  }
}